<template>
  <div class="footer">
    {{official_display ? `©2020 - ${new Date().getFullYear()} 广州健食慧软件技术有限公司 版权所有` : ""}} 版本：{{version}}</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('school', [
      'official_display'
    ]),
    version () {
      return `${process.env.npm_package_version}`
    }
  }
}
</script>
<style lang="less">
.footer {
  text-align: center;
  padding: 20px 0;
}
</style>
